import React, { useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { NWClient } from '../../../../../../client/NWClient';
import { StyledTableCell } from '../TableCell';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../../../../../common/dialog-item/DialogItem';
import { Link, useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { ROUTES } from '../../../../../../common/constants/routes';
import { Helpers } from '../../../../../../common/helpers/helpers';
import ContractGeneratorDialog from '../ContractGeneratorDialog';
import { ProgressOverlay } from '../../../../../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../../../../../slices/companies/companiesSlice';

export type ActionsCellProps = {
  row: {
    id: number;
    name?: string;
    category?: string;
    region?: string;
    document_upload?: number;
    user?: number;
  };
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit';
};

export const ActionsCell = ({ row, align = 'left' }: ActionsCellProps) => {
  const token = localStorage.getItem('access_token');
  const company = useAppSelector(selectAllCompanies)[0];
  const { name } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [errorText, setErrorText] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [generatorOpened, setGeneratorOpened] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);

  const errorMessageTitle = t('messages.errorOccurred');
  const NoPermissionMessage = () => {
    return (
      <>
        {t('messages.startSubscription')}&nbsp;<Link to={ROUTES.PRICING}>{t('links.here')}</Link>.
      </>
    );
  };
  const noPermissionTitle = t('messages.downloadMessageTitle');

  const setError = (errorMessage: string | null) => {
    setErrorState(true);
    setErrorText(errorMessage);
  };

  const handleDownloadLink = (id: number) => {
    setDownloadLoading(true);
    NWClient.downloadTemplate(token, id, row.name)
      .catch((error) => {
        const status = error.response && error.response.status;
        setErrorStatus(status);
        const errorMessage = Helpers.returnErrorText(error);
        status === 405
          ? navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${location.pathname}`)
          : status !== 403
          ? setError(errorMessage.toString())
          : setError(null);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const handleGenerateTemplate = (data: {
    user: number;
    document_library: number;
    company_id: number;
    secondary_id: number;
    category: string;
  }) => {
    setPendingStatus(true);
    NWClient.post(token, 'ai-contract', data)
      .then(
        (res: {
          id: number;
          user: number;
          document_library: number;
          placeholder_matches: { [key: string]: string };
          category: string;
        }) => {
          toast.success(t('messages.templateGenerated'), { theme: 'colored' });
          navigate(
            `${
              name === 'uk' || name === 'au'
                ? `${ROUTES.DOCUMENT_LIBRARY}/${row.region.toLowerCase()}/`
                : `${ROUTES.CATEGORY_LIST}/${row.category}/`
            }${res.id}`
          );
        }
      )
      .catch((error) => {
        const status = error.response && error.response.status;
        setErrorStatus(status);
        const errorMessage = Helpers.returnErrorText(error);
        setErrorState(true);
        status !== 403 ? setErrorText(errorMessage.toString()) : setErrorText(null);
        setPendingStatus(false);
      });
  };

  return (
    <>
      <StyledTableCell align={align}>
        <Grid container justifyContent='flex-end'>
          <Grid item xs={12} className='d-flex align-items-center justify-content-end'>
            {name !== 'uk' && name !== 'au' && name !== 'templates' && (
              <Button
                onClick={() => {
                  handleDownloadLink(row.id);
                }}
                className='templates-table-action'
                aria-label={`download ${row.name} button`}
                disabled={downloadLoading}
              >
                {!downloadLoading ? t('buttons.download') : <CircularProgress size={20} />}
              </Button>
            )}
            {!process.env.ENV_TYPE ||
            ((name === 'uk' || name === 'au' || name === 'templates') && company) ? (
              <Button
                onClick={() => {
                  setGeneratorOpened(true);
                }}
                className='templates-table-action'
                aria-label={`create ${row.name} button`}
                disabled={downloadLoading}
              >
                {t('buttons.create')}
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <DialogItem
          isErrorMessage={errorState && errorStatus !== 403}
          open={errorState}
          title={errorStatus !== 403 ? errorMessageTitle : noPermissionTitle}
          text={errorText}
          handleClose={() => setErrorState(false)}
        >
          {errorStatus === 403 ? <NoPermissionMessage /> : null}
        </DialogItem>
        {company && (
          <ContractGeneratorDialog
            templateId={row.id}
            open={generatorOpened}
            handleClose={() => setGeneratorOpened(false)}
            templateTitle={row.name}
            handleGenerateTemplate={handleGenerateTemplate}
          />
        )}
        {pendingStatus ? <ProgressOverlay /> : ''}
      </StyledTableCell>
    </>
  );
};
