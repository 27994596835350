import React, { ChangeEvent, useEffect, useState } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { CircularProgress, Divider, FormHelperText, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { selectUser, fetchUser } from '../../../../slices/user/userSlice';
import { Helpers } from '../../../../common/helpers/helpers';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { FORM_ITEMS } from '../../../../common/constants/form_items';
import FormErrorText from '../../../../common/form-error-text/form-error-text';
import DialogItem from '../../../../common/dialog-item/DialogItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const AccountDetailsTab = ({ currentTab }: TabProps) => {
  const token = localStorage.getItem('access_token');
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string | null>('');
  const [email, setEmail] = useState<string | null>('');
  const [address, setAddress] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [errorTextArray, setErrorTextArray] = useState([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const { t } = useTranslation();

  const canSubmit = Boolean(firstName) && Boolean(lastName);

  const resetPasswordMessageTitle = t('passwordReset.header2');
  const resetPasswordMessage = t('passwordReset.message');

  const setUserInfo = () => {
    setFirstName(currentUser.first_name);
    setLastName(currentUser.last_name);
    setEmail(currentUser.email);
    setAddress(currentUser.address ? currentUser.address : '');
  };

  const handleInputsPossibleErrors = (propertyName: string) => {
    if (fieldsErrors && fieldsErrors[propertyName]) {
      const obj = { ...fieldsErrors };
      delete obj[propertyName];
      setFieldsErrors(obj);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      first_name: firstName,
      last_name: lastName,
      address,
    };
    NWClient.put(token, 'user/update_details', currentUser.id, data)
      .then(() => {
        setPendingStatus(false);
        setErrorTextArray([]);
        dispatch(fetchUser(token)); //to refactor;
        toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
      })
      .catch((error) => {
        setFieldsErrors(error.response.data);
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  const handlePasswordReset = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      email: email.trim().toLowerCase(),
    };
    NWClient.resetPasswordEmail(data)
      .then(() => {
        setPendingStatus(false);
        setShowMessage(true);
        setErrorTextArray([]);
      })
      .catch((error) => {
        Helpers.handleRequestError(error.response, setErrorTextArray, setPendingStatus);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (currentUser) {
      setLoading(false);
      setUserInfo();
    } else {
      dispatch(fetchUser(token))
        .then(() => {
          setUserInfo();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentUser, dispatch, token]);

  return (
    <TabPanel value={currentTab} index={0} prefix={'vertical'}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid>
            <Grid container item className='setting-panel-header' alignItems='center'>
              <AccountCircleIcon fontSize='large' />
              <h3>{t('settings.titles.account')}</h3>
            </Grid>
            <Divider />
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.firstName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                      name='firstName'
                      value={firstName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.lastName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                      name='lastName'
                      value={lastName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.email')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                      name='email'
                      value={email}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.postalAddress')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setAddress(e.target.value);
                        handleInputsPossibleErrors('address');
                      }}
                      name='address'
                      value={address}
                    />
                    {Boolean(fieldsErrors?.address) && (
                      <FormHelperText error={true}>
                        {fieldsErrors?.address.join('.')}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className='link-container'>
                      <span
                        role='button'
                        tabIndex={0}
                        className='forgot-password-link'
                        onClick={(e) => handlePasswordReset(e)}
                        onKeyUp={(e) => console.log(e)}
                      >
                        {t('buttons.changePassword')}
                      </span>
                    </div>
                    <FormErrorText
                      id={FORM_ITEMS.ERROR_CONTAINER_ACCOUNT_EMAIL_ID}
                      errorTextArray={errorTextArray}
                    />
                  </Grid>
                </Grid>
                <Grid container className='buttons-row' justifyContent='flex-end'>
                  <Button
                    type='submit'
                    role='button'
                    disabled={!canSubmit}
                    variant='contained'
                    size='medium'
                  >
                    Update
                  </Button>
                </Grid>
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
          <DialogItem
            isErrorMessage={false}
            open={showMessage}
            title={resetPasswordMessageTitle}
            text={resetPasswordMessage}
            handleClose={() => setShowMessage(false)}
          />
        </>
      )}
    </TabPanel>
  );
};
