import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NWClient } from '../../client/NWClient';
import { ROUTES } from '../../common/constants/routes';
import { useTranslation } from 'react-i18next';

const Confirmation = () => {
  const [searchParams] = useSearchParams();
  const [errorCatched, setErrorCatched] = useState(false);
  const [errorText, setErrorText] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const uidb = searchParams.get('uidb');
  const token = searchParams.get('token');

  useEffect(() => {
    NWClient.confirmSignUp(uidb, token)
      .then(() => {
        navigate(`${ROUTES.PROCESS_SUCCESS}/?account_confirmed=true`);
      })
      .catch((res) => {
        setErrorCatched(true);
        const text = res.message || res.response.statusText;
        setErrorText(text);
      });
  }, [token, uidb, navigate]);
  return (
    <main className='fullscreen-container confirmation-page'>
      <Box
        sx={{
          maxWidth: 400,
          textAlign: 'center',
        }}
      >
        <div className='image-container'>
          <MarkEmailReadOutlinedIcon />
        </div>
        <div className='confirmation-message'>{t('messages.confirmingAccount')}</div>
        {!errorCatched ? <CircularProgress style={{ margin: '0.5rem 0' }} color='secondary' /> : ''}
        {errorCatched ? <div className='mui-danger'>{errorText}</div> : ''}
      </Box>
    </main>
  );
};

export default Confirmation;
