import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { HTMLTemplateModel } from '../../models/html.template.model';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import parse from 'html-react-parser';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectUser } from '../../slices/user/userSlice';
import { AIContractModel } from '../../models/ai.contract.model';
import { ROUTES } from '../../common/constants/routes';
import { addDocumentContract } from '../../slices/document-contracts/documentContractsSlice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';

const TemplateItem = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [placeholders, setPlaceholders] = useState<{
    [key: string]: string;
  }>({});
  const [contractItem, setContractItem] = useState<AIContractModel>(null);
  const [templateItem, setTemplateItem] = useState<HTMLTemplateModel>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPlaceholders, setLoadingPlaceholders] = useState<boolean>(true);
  const [loadingHTML, setLoadingHTML] = useState<boolean>(true);

  const createDocumentFromTemplate = () => {
    setLoading(true);
    const data = {
      user: currentUser.id,
      contract_generation: Number(id),
      name: templateItem?.name + '_' + new Date().toISOString(),
      category: contractItem?.category,
      /*placeholders: placeholders,*/
      /*html: templateItem?.html,*/
    };
    const contractData = { ...contractItem };
    delete contractData.id;
    contractData.placeholder_matches = placeholders;
    NWClient.put(token, 'ai-contract', Number(id), contractData, true)
      .then(() => {
        dispatch(addDocumentContract({ token, data }))
          .then(() => {
            toast.success(t('messages.contractCreated'), {
              theme: 'colored',
            });
            navigate(`${ROUTES.DOCUMENT_VAULT}/${contractItem.category}`);
          })
          .catch((error) => {
            toast.error(error.message ? error.message : t('messages.errorOccurred'), {
              theme: 'colored',
            });
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message ? error.message : t('messages.errorOccurred'), {
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    if (id) {
      NWClient.get(token, 'ai-contract', Number(id))
        .then((res: AIContractModel) => {
          setPlaceholders(res.placeholder_matches);
          setContractItem(res);
          NWClient.get(token, 'document-library', Number(res.document_library))
            .then((res: HTMLTemplateModel) => {
              setTemplateItem(res);
            })
            .catch((error) => {
              toast.error(error.message ? error.message : t('messages.errorOccurred'), {
                theme: 'colored',
              });
            })
            .finally(() => {
              setLoadingHTML(false);
            });
        })
        .catch((error) => {
          toast.error(error.message ? error.message : t('messages.errorOccurred'), {
            theme: 'colored',
          });
        })
        .finally(() => {
          setLoadingPlaceholders(false);
        });
    }
  }, []);
  return (
    <>
      <h1 className='mb-4'>{`${
        templateItem ? templateItem.name : t('documentPage.headerDefault')
      }`}</h1>
      <div>
        <div className='ms-0 me-0 buttons-row content-buttons align-items-center bg-white position-sticky p-4 d-flex flex-wrap justify-content-end'>
          <Button
            onClick={() => createDocumentFromTemplate()}
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            type='button'
            role='button'
            variant='contained'
            size='medium'
          >
            {t('buttons.create')}
          </Button>
        </div>
        <div
          className='d-flex position-relative'
          style={{ border: '1px solid #C7C6C6', borderTop: 'none' }}
        >
          <div
            className='p-2 w-50 overflow-auto flex-shrink-0 position-relative'
            style={{ height: 'calc(100vh - 280px)' }}
          >
            <table className='w-100 template-placeholders-table'>
              <thead>
                <tr>
                  <th>{t('labels.placeholder')}</th>
                  <th>{t('labels.value')}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(placeholders).map((el, i) => (
                  <tr key={Object.keys(placeholders)[i]}>
                    <td>
                      <input type='text' disabled value={Object.keys(placeholders)[i]} />
                    </td>
                    <td>
                      <input
                        type='text'
                        onChange={(e) => {
                          const placeholdersCopy = { ...placeholders };
                          placeholdersCopy[el] = e.target.value;
                          setPlaceholders(placeholdersCopy);
                        }}
                        value={placeholders[el]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingPlaceholders ? <ProgressOverlay className='position-absolute' /> : null}
          </div>
          <div
            className='p-2 w-50 overflow-auto flex-shrink-0 position-relative'
            style={{ borderLeft: '1px solid #C7C6C6', height: 'calc(100vh - 280px)' }}
          >
            {templateItem ? <div>{parse(templateItem.html)}</div> : null}
            {loadingHTML ? <ProgressOverlay className='position-absolute' /> : null}
          </div>
        </div>
      </div>
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};

export default TemplateItem;
