import React from 'react';
import FeatureSlide, { FeatureSlideProps } from './FeatureSlide';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface FeaturesCarouselProps {
  itemsArray: FeatureSlideProps[];
}

const FeaturesCarousel = (props: FeaturesCarouselProps) => {
  return (
    <Carousel
      autoPlay={true}
      interval={5000}
      infiniteLoop={true}
      showThumbs={false}
      showArrows={false}
      showStatus={false}
    >
      {props.itemsArray.map((item, i) => (
        <FeatureSlide
          slideTitle={item.slideTitle}
          descriptionText={item.descriptionText}
          imageSrc={item.imageSrc}
          withReleasedLabel={item.withReleasedLabel}
          key={i}
        />
      ))}
    </Carousel>
  );
};

export default FeaturesCarousel;
