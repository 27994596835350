import React, { ChangeEvent, useEffect, useState } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { selectUser, fetchUser } from '../../../../slices/user/userSlice';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import EmploymentSettingsIcon from '../../../../../static/assets/svg/menu/profile-icon.svg';
import MenuItem from '@mui/material/MenuItem';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import 'dayjs/locale/en-gb';
import { POSITION_TYPES } from '../../../../common/constants/position_types';

export const EmploymentDetailsTab = ({ currentTab }: TabProps) => {
  const token = localStorage.getItem('access_token');
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [positionTitle, setPositionTitle] = useState<string>('');
  const [positionType, setPositionType] = useState<string | null>('');
  const [department, setDepartment] = useState<string | null>('');
  const [taxNumber, setTaxNumber] = useState<string | null>('');
  const [employeeId, setEmployeeId] = useState<string | null>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [positionDescription, setPositionDescription] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      position_title: positionTitle,
      position_description: positionDescription,
      position_type: positionType,
      department: department,
      start_date: startDate ? startDate.toISOString().substring(0, 10) : null,
      end_date: endDate ? endDate.toISOString().substring(0, 10) : null,
      employee_tax_number: taxNumber,
      employee_id: employeeId,
      employee_details_id: currentUser.details ? currentUser.details.id : null,
    };
    if (!currentUser.details) {
      NWClient.post(token, 'employee_details', { user: currentUser.id })
        .then(() => {
          dispatch(fetchUser(token))
            .unwrap()
            .then((res) => {
              if (res.details && res.details.employments.length === 0) {
                data.employee_details_id = res.details.id;
                NWClient.post(token, `employee_details/${res.details.id}/employments`, data, true)
                  .then(() => {
                    setPendingStatus(false);
                    dispatch(fetchUser(token)); //to refactor
                    toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
                  })
                  .catch(() => {
                    setPendingStatus(false);
                    toast.error(t('messages.errorOccurred'), { theme: 'colored' });
                  });
              }
            });
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    } else {
      if (currentUser.details.employments.length === 0) {
        NWClient.post(token, `employee_details/${currentUser.details.id}/employments`, data, true)
          .then(() => {
            setPendingStatus(false);
            dispatch(fetchUser(token)); //to refactor
            toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      } else {
        NWClient.put(
          token,
          `employee_details/${currentUser.details.id}/employments`,
          currentUser.details.employments[0].id,
          data,
          true,
          true
        )
          .then(() => {
            setPendingStatus(false);
            dispatch(fetchUser(token)); //to refactor
            toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      }
    }
  };

  const setEmploymentDetails = () => {
    if (currentUser.details && currentUser.details.employments.length > 0) {
      const employmentInstance = currentUser.details.employments[0];
      setPositionTitle(employmentInstance.position_title);
      setDepartment(employmentInstance.department);
      setPositionType(employmentInstance.position_type);
      setPositionDescription(employmentInstance.position_description);
      setStartDate(
        employmentInstance.start_date ? dayjs(new Date(employmentInstance.start_date)) : null
      );
      setEndDate(employmentInstance.end_date ? dayjs(new Date(employmentInstance.end_date)) : null);
      setEmployeeId(employmentInstance.employee_id);
      setTaxNumber(employmentInstance.employee_tax_number);
    }
  };

  const canSubmit = true; //Boolean(positionTitle);

  const positionTypeArray = POSITION_TYPES.map((el) => t(el));

  useEffect(() => {
    setLoading(true);
    if (currentUser) {
      setLoading(false);
      setEmploymentDetails();
    } else {
      dispatch(fetchUser(token))
        .then(() => {
          setEmploymentDetails();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentUser, dispatch, token]);

  return (
    <TabPanel value={currentTab} index={1} prefix={'vertical'}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid>
            <Grid container item className='setting-panel-header' alignItems='center'>
              <EmploymentSettingsIcon />
              <h3>{t('settings.titles.employment')}</h3>
            </Grid>
            <Divider />
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.employeeId')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setEmployeeId(e.target.value)}
                      name='employeeId'
                      value={employeeId}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.employeeTaxNumber')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setTaxNumber(e.target.value)}
                      name='employeeTaxNumber'
                      value={taxNumber}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.positionTitle')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPositionTitle(e.target.value)
                      }
                      name='positionTitle'
                      value={positionTitle}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.department')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setDepartment(e.target.value)}
                      name='department'
                      value={department}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      select
                      label={t('labels.positionType')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPositionType(e.target.value)
                      }
                      name='positionType'
                      value={positionType}
                    >
                      {positionTypeArray.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Grid>
                  <Grid item xs={12} md={6} className='datepicker-container'>
                    <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          aria-labelledby='startDateLabel'
                          format='DD.MM.YYYY'
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          slotProps={{
                            field: { clearable: true },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} className='datepicker-container'>
                    <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          aria-labelledby='endDateLabel'
                          format='DD.MM.YYYY'
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                          slotProps={{
                            field: { clearable: true },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor='positionDescription'>
                      {t('labels.positionDescription')}
                    </InputLabel>
                    <textarea
                      id='positionDescription'
                      name='positionDescription'
                      value={positionDescription}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        setPositionDescription(e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container className='buttons-row' justifyContent='flex-end'>
                  <Button
                    type='submit'
                    role='button'
                    disabled={!canSubmit}
                    variant='outlined'
                    size='large'
                  >
                    {t('buttons.update')}
                  </Button>
                </Grid>
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
        </>
      )}
    </TabPanel>
  );
};
