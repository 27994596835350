import { useSelector } from 'react-redux';
import {
  fetchUser,
  selectUser,
  selectUserFetchStatus,
  logout,
} from '../../../slices/user/userSlice';
import { fetchUserSigningInfo } from '../../../slices/user-signing-info/userSigningInfoSlice';
import { useEffect } from 'react';
import { ROUTES } from '../../../common/constants/routes';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';

export const useUserInfo = () => {
  const token = localStorage.getItem('access_token');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(selectUser);
  const fetchUserStatus = useSelector(selectUserFetchStatus);

  const locationExceptions = [
    ROUTES.SIGN_UP,
    ROUTES.EMAIL_SENT,
    ROUTES.PROCESS_SUCCESS,
    ROUTES.CONFIRMATION,
    ROUTES.LOGIN,
    ROUTES.ACCEPTED_INVITE,
    ROUTES.PASSWORD_RESET,
  ];

  const locationIncludeExceptionUrl = locationExceptions.some((el) => {
    return location.pathname.includes(el);
  });

  useEffect(() => {
    if (token && !locationIncludeExceptionUrl) {
      dispatch(fetchUser(token))
        .unwrap()
        .then(() => {
          dispatch(fetchUserSigningInfo(token));
        })
        .catch(() => {
          navigate(ROUTES.LOGIN);
        });
    } else if (!token && !locationIncludeExceptionUrl) {
      navigate(ROUTES.LOGIN);
      //dispatch(logout());
    }
  }, [dispatch, locationIncludeExceptionUrl, navigate, token]);

  /*if (fetchUserStatus === 'failed') {
    localStorage.removeItem('access_token');
    //navigate(ROUTES.LOGIN);
    //dispatch(logout());
  }*/

  return {
    currentUser,
    userLoading: fetchUserStatus === 'loading',
  };
};
