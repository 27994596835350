import React, { ChangeEvent, useEffect, useState } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { selectUser, fetchUser } from '../../../../slices/user/userSlice';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import QualificationsIcon from '../../../../../static/assets/svg/menu/qualifications.svg';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import 'dayjs/locale/en-gb';

export const QualificationsTab = ({ currentTab }: TabProps) => {
  const token = localStorage.getItem('access_token');
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [degree, setDegree] = useState<string>('');
  const [universityName, setUniversityName] = useState<string | null>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      degree,
      university_name: universityName,
      start_date: startDate ? startDate.toISOString().substring(0, 10) : null,
      end_date: endDate ? endDate.toISOString().substring(0, 10) : null,
      employee_details_id: currentUser.details ? currentUser.details.id : null,
    };
    if (!currentUser.details) {
      NWClient.post(token, 'employee_details', { user: currentUser.id })
        .then(() => {
          dispatch(fetchUser(token))
            .unwrap()
            .then((res) => {
              if (res.details && res.details.qualifications.length === 0) {
                data.employee_details_id = res.details.id;
                NWClient.post(
                  token,
                  `employee_details/${res.details.id}/qualifications`,
                  data,
                  true
                )
                  .then(() => {
                    setPendingStatus(false);
                    dispatch(fetchUser(token)); //to refactor
                    toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
                  })
                  .catch(() => {
                    setPendingStatus(false);
                    toast.error(t('messages.errorOccurred'), { theme: 'colored' });
                  });
              }
            });
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    } else {
      if (currentUser.details.qualifications.length === 0) {
        NWClient.post(
          token,
          `employee_details/${currentUser.details.id}/qualifications`,
          data,
          true
        )
          .then(() => {
            setPendingStatus(false);
            dispatch(fetchUser(token)); //to refactor
            toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      } else {
        NWClient.put(
          token,
          `employee_details/${currentUser.details.id}/qualifications`,
          currentUser.details.qualifications[0].id,
          data,
          true,
          true
        )
          .then(() => {
            setPendingStatus(false);
            dispatch(fetchUser(token)); //to refactor
            toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      }
    }
  };

  const setQualificationsDetails = () => {
    if (currentUser.details && currentUser.details.qualifications.length > 0) {
      const qualificationsInstance = currentUser.details.qualifications[0];
      setDegree(qualificationsInstance.degree);
      setUniversityName(qualificationsInstance.university_name);
      setStartDate(
        qualificationsInstance.start_date
          ? dayjs(new Date(qualificationsInstance.start_date))
          : null
      );
      setEndDate(
        qualificationsInstance.end_date ? dayjs(new Date(qualificationsInstance.end_date)) : null
      );
    }
  };

  const canSubmit = true;

  useEffect(() => {
    setLoading(true);
    if (currentUser) {
      setLoading(false);
      setQualificationsDetails();
    } else {
      dispatch(fetchUser(token))
        .then(() => {
          setQualificationsDetails();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentUser, dispatch, token]);

  return (
    <TabPanel value={currentTab} index={2} prefix={'vertical'}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid>
            <Grid container item className='setting-panel-header' alignItems='center'>
              <QualificationsIcon />
              <h3>{t('settings.titles.qualifications')}</h3>
            </Grid>
            <Divider />
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.degreeOrCertificate')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setDegree(e.target.value)}
                      name='degree'
                      value={degree}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.institutionName')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setUniversityName(e.target.value)
                      }
                      name='universityName'
                      value={universityName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className='datepicker-container'>
                    <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          aria-labelledby='startDateLabel'
                          format='DD.MM.YYYY'
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          slotProps={{
                            field: { clearable: true },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} className='datepicker-container'>
                    <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          aria-labelledby='endDateLabel'
                          format='DD.MM.YYYY'
                          value={endDate}
                          defaultValue={null}
                          onChange={(newValue) => setEndDate(newValue)}
                          slotProps={{
                            field: { clearable: true },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container className='buttons-row' justifyContent='flex-end'>
                  <Button
                    type='submit'
                    role='button'
                    disabled={!canSubmit}
                    variant='outlined'
                    size='large'
                  >
                    {t('buttons.update')}
                  </Button>
                </Grid>
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
        </>
      )}
    </TabPanel>
  );
};
