import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import React from 'react';

export const ProgressOverlay = (props: CircularProgressProps) => {
  const { size, color, className } = props;
  return (
    <div className={`progress-wrapper ${className}`}>
      <CircularProgress size={size || 60} color={color || 'primary'} />
    </div>
  );
};
