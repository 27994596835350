import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
//import { listCategories } from '../store/actions';
import { useParams } from 'react-router-dom';
import { fetchCategories, selectAllCategories } from '../../../slices/categories/categoriesSlice';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';

export const useCategoryList = () => {
  const categories = useSelector(selectAllCategories);
  const { name } = useParams();
  const [fetchCategoriesDispatched, setFetchCategoriesDispatched] = useState<boolean>(false);
  const token = localStorage.getItem('access_token');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token && categories.length === 0 && !fetchCategoriesDispatched) {
      setFetchCategoriesDispatched(true);
      dispatch(fetchCategories(token));
      //listCategories(token, dispatch);
    }
  }, [dispatch, token, categories, fetchCategoriesDispatched]);

  const categoriesWithSubcategories = useMemo(() => {
    return categories
      ? categories.reduce((tempCategories, parentCategory) => {
          //const isChildren = Boolean(parentCategory.parent);
          const isParent = parentCategory.sub_categories.length > 0;
          /*if (!isChildren)*/
          if (isParent) {
            if ((name && name.toLowerCase() === parentCategory.name.toLowerCase()) || !name) {
              const subcategories = categories.filter(
                (category) => category.parent === parentCategory.id
              );
              tempCategories.push({
                ...parentCategory,
                subcategories,
              });
            }
          }

          return tempCategories;
        }, [])
      : [];
  }, [categories, name]);

  return {
    categories: categoriesWithSubcategories,
    isGroup: Boolean(name),
  };
};
