import React, { ChangeEvent, useState, useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Divider, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { fetchCompanies, selectAllCompanies } from '../../../slices/companies/companiesSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { NWClient } from '../../../client/NWClient';
import { toast } from 'react-toastify';
import 'dayjs/locale/en-gb';
import { Country } from 'country-state-city';
import { INDUSTRY_TYPES } from '../../../common/constants/industry_types';
import InputLabel from '@mui/material/InputLabel';
import { ROUTES } from '../../../common/constants/routes';
import { Supplier } from '../../../models/supplier.model';
import LocationIcon from '../../../../static/assets/svg/menu/address-location-icon.svg';
import MailIcon from '../../../../static/assets/svg/menu/mail-icon.svg';
import SupplierIndustryIcon from '../../../../static/assets/svg/menu/supply-chain-icon.svg';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogItem from '../../../common/dialog-item/DialogItem';
import PersonalDetailsIcon from '../../../../static/assets/svg/menu/personal-details.svg';

const SupplierProfileForm = () => {
  const { supplierId } = useParams();
  const company = useAppSelector(selectAllCompanies)[0];
  const supplier = company?.suppliers.find((el) => el.id === Number(supplierId));
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('access_token');
  const [pendingStatus, setPendingStatus] = useState(false);
  const [name, setName] = useState<string | null>('');
  const [registrationNumber, setRegistrationNumber] = useState<string | null>('');
  const [postalAddress, setPostalAddress] = useState<string | null>('');
  const [postalCode, setPostalCode] = useState<string | null>('');
  const [postalCity, setPostalCity] = useState<string | null>('');
  const [postalCountry, setPostalCountry] = useState<string | null>('');
  const [physicalAddress, setPhysicalAddress] = useState<string | null>('');
  const [physicalCode, setPhysicalCode] = useState<string | null>('');
  const [physicalCity, setPhysicalCity] = useState<string | null>('');
  const [physicalCountry, setPhysicalCountry] = useState<string | null>('');
  const [website, setWebsite] = useState<string | null>('');
  const [supplierType, setSupplierType] = useState<string | null>('');
  const [supplierIndustry, setSupplierIndustry] = useState<string | null>('');
  const [description, setDescription] = useState<string | null>('');
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deleteSupplierMessageVisible, setDeleteSupplierMessageVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const countries = Country.getAllCountries();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const supplierTypes = INDUSTRY_TYPES.map((el) => t(el));
  const canSubmit = true;

  const updateSupplier = () => {
    setPendingStatus(true);
    const data = {
      company: company.id,
      name: name,
      registration_number: registrationNumber,
      postal_address: postalAddress,
      postal_code: postalCode,
      postal_city: postalCity,
      postal_country: postalCountry,
      physical_address: physicalAddress,
      physical_code: physicalCode,
      physical_city: physicalCity,
      physical_country: physicalCountry,
      website: website,
      supplier_type: supplierType,
      industry: supplierIndustry,
      description: description,
    };
    NWClient.put(token, 'supplier_profile', Number(supplierId), data, true, true)
      .then(() => {
        dispatch(fetchCompanies(token));
        toast.success(t('messages.supplierUpdated'), { theme: 'colored' });
        setEditMode(false);
        setPendingStatus(false);
      })
      .catch((error) => {
        setPendingStatus(false);
        setFieldsErrors(error.response.data);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      company: company.id,
      name: name,
      registration_number: registrationNumber,
      postal_address: postalAddress,
      postal_code: postalCode,
      postal_city: postalCity,
      postal_country: postalCountry,
      physical_address: physicalAddress,
      physical_code: physicalCode,
      physical_city: physicalCity,
      physical_country: physicalCountry,
      website: website,
      supplier_type: supplierType,
      industry: supplierIndustry,
      description: description,
    };
    if (supplierId) {
      updateSupplier();
    } else {
      NWClient.post(token, 'supplier_profile', data)
        .then((res: Supplier) => {
          dispatch(fetchCompanies(token));
          toast.success(t('messages.supplierCreated'), { theme: 'colored' });
          navigate(ROUTES.SUPPLIER_PROFILES + '/' + res.id);
        })
        .catch((error) => {
          setPendingStatus(false);
          setFieldsErrors(error.response.data);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };

  const deleteSupplier = () => {
    setPendingStatus(true);
    NWClient.delete(token, 'supplier_profile', supplierId)
      .then(() => {
        toast.success(t('messages.supplierDeleted'), { theme: 'colored' });
        dispatch(fetchCompanies(token))
          .unwrap()
          .then(() => {
            navigate(ROUTES.SUPPLIER_PROFILES);
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      })
      .catch(() => {
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  const setDetails = () => {
    if (supplier) {
      setSupplierType(supplier.supplier_type);
      setSupplierIndustry(supplier.industry);
      setPhysicalAddress(supplier.physical_address);
      setPostalAddress(supplier.postal_address);
      setPostalCity(supplier.postal_city);
      setPostalCode(supplier.postal_code);
      setPostalCountry(supplier.postal_country);
      setPhysicalCity(supplier.physical_city);
      setPhysicalCode(supplier.physical_code);
      setPhysicalCountry(supplier.physical_country);
      setDescription(supplier.description);
      setName(supplier.name);
      setRegistrationNumber(supplier.registration_number);
      setWebsite(supplier.website);
    }
  };

  useEffect(() => {
    if (company) {
      setDetails();
      setLoading(false);
    } else {
      dispatch(fetchCompanies(token))
        .then(() => {
          setDetails();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company, dispatch, token]);
  return (
    <>
      {loading ? (
        <div className='circular-progress-container'>
          <CircularProgress size={60} />
        </div>
      ) : (
        <div className='profile-details-wrapper'>
          <Grid>
            <div>
              <ValidatorForm onSubmit={handleSubmit}>
                <div className='basic-info'>
                  <Grid
                    container
                    item
                    className='setting-panel-header justify-content-between'
                    alignItems='center'
                  >
                    <div className='d-flex align-items-start header'>
                      <PersonalDetailsIcon />
                      <h2>{t('supplierTabs.basicDetails')}</h2>
                    </div>
                    {supplier && (
                      <div className='d-flex flex-wrap buttons'>
                        <Button
                          style={{ marginLeft: '0.5rem' }}
                          variant='contained'
                          size='large'
                          onClick={() => {
                            if (editMode) {
                              updateSupplier();
                            } else {
                              setEditMode(true);
                            }
                          }}
                        >
                          {!editMode ? t('buttons.edit') : t('buttons.update')}
                        </Button>
                      </div>
                    )}
                  </Grid>
                  <Divider />
                  <div className='setting-panel-body'>
                    <Grid container className='form-controls' spacing={4}>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.supplierName')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                          name='name'
                          value={name}
                          disabled={supplierId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.supplierRegistrationNumber')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setRegistrationNumber(e.target.value)
                          }
                          name='registrationNumber'
                          value={registrationNumber}
                          disabled={supplierId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.website')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setWebsite(e.target.value)
                          }
                          name='website'
                          disabled={supplierId ? !editMode : false}
                          value={website}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel htmlFor='supplierDescription'>
                          {t('labels.supplierDescription')}
                        </InputLabel>
                        <textarea
                          id='supplierDescription'
                          name='description'
                          value={description}
                          disabled={supplierId ? !editMode : false}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            setDescription(e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Grid container item className='setting-panel-header' alignItems='center'>
                  <SupplierIndustryIcon />
                  <h2>{t('labels.supplierIndustry')}</h2>
                </Grid>
                <Divider />
                <div className='setting-panel-body'>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        select
                        label={t('labels.type')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setSupplierType(e.target.value)
                        }
                        name='supplierType'
                        disabled={supplierId ? !editMode : false}
                        value={supplierType}
                      >
                        {supplierTypes.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextValidator>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.industry')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setSupplierIndustry(e.target.value)
                        }
                        disabled={supplierId ? !editMode : false}
                        name='industry'
                        value={supplierIndustry}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Grid container item className='setting-panel-header' alignItems='center'>
                  <MailIcon />
                  <h2>{t('labels.postalAddress')}</h2>
                </Grid>
                <Divider />
                <div className='setting-panel-body'>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.postalAddress')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPostalAddress(e.target.value)
                        }
                        name='postalAddress'
                        value={postalAddress}
                        disabled={supplierId ? !editMode : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.postalCode')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPostalCode(e.target.value)
                        }
                        name='postalCode'
                        value={postalCode}
                        disabled={supplierId ? !editMode : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.cityCounty')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPostalCity(e.target.value)
                        }
                        name='postalCity'
                        value={postalCity}
                        disabled={supplierId ? !editMode : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        select
                        label={t('labels.country')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPostalCountry(e.target.value)
                        }
                        name='postalCountry'
                        disabled={supplierId ? !editMode : false}
                        value={postalCountry}
                      >
                        {countries.map((option) => (
                          <MenuItem key={option.isoCode} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextValidator>
                    </Grid>
                  </Grid>
                </div>
                <Grid container item className='setting-panel-header' alignItems='center'>
                  <LocationIcon />
                  <h2>{t('labels.physicalAddress')}</h2>
                </Grid>
                <Divider />
                <div className='setting-panel-body'>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.physicalAddress')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPhysicalAddress(e.target.value)
                        }
                        disabled={supplierId ? !editMode : false}
                        name='physicalAddress'
                        value={physicalAddress}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.postalCode')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPhysicalCode(e.target.value)
                        }
                        name='physicalCode'
                        value={physicalCode}
                        disabled={supplierId ? !editMode : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.cityCounty')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPhysicalCity(e.target.value)
                        }
                        name='physicalCity'
                        value={physicalCity}
                        disabled={supplierId ? !editMode : false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        select
                        label={t('labels.country')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPhysicalCountry(e.target.value)
                        }
                        name='physicalCountry'
                        disabled={supplierId ? !editMode : false}
                        value={physicalCountry}
                      >
                        {countries.map((option) => (
                          <MenuItem key={option.isoCode} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextValidator>
                    </Grid>
                  </Grid>
                </div>
                {(editMode || !supplierId) && (
                  <Grid container className='buttons-row pb-3' justifyContent='flex-end'>
                    {supplierId && (
                      <IconButton
                        aria-label='delete'
                        style={{ marginLeft: '0.5rem' }}
                        onClick={() => setDeleteSupplierMessageVisible(true)}
                      >
                        <Tooltip title={t('messages.deleteSupplier')} placement='top' arrow>
                          <DeleteOutlineOutlinedIcon fontSize='large' style={{ marginRight: 0 }} />
                        </Tooltip>
                      </IconButton>
                    )}
                    <Button
                      type='submit'
                      role='button'
                      disabled={!canSubmit}
                      style={{ marginLeft: '0.5rem' }}
                      variant='contained'
                      size='large'
                    >
                      {editMode ? t('buttons.update') : t('buttons.createSupplier')}
                    </Button>
                  </Grid>
                )}
              </ValidatorForm>
            </div>
          </Grid>
        </div>
      )}
      {pendingStatus ? <ProgressOverlay /> : null}
      <DialogItem
        isErrorMessage={false}
        open={deleteSupplierMessageVisible}
        title={
          deleteSupplierMessageVisible
            ? t('dialogTitles.deleteSupplier')
            : t('dialogTitles.deleteDefault')
        }
        text={
          deleteSupplierMessageVisible
            ? t('messages.deleteSupplierDialog')
            : t('messages.deleteDefault')
        }
        noDefaultActionsRow={true}
        handleClose={() => {
          setDeleteSupplierMessageVisible(false);
        }}
      >
        <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
          <Button
            type='button'
            role='button'
            variant='outlined'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => deleteSupplier()}
          >
            {t('buttons.delete')}
          </Button>
          <Button
            type='button'
            role='button'
            variant='contained'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => {
              setDeleteSupplierMessageVisible(false);
            }}
          >
            {t('buttons.goBack')}
          </Button>
        </div>
      </DialogItem>
    </>
  );
};

export default SupplierProfileForm;
