import React, { ChangeEvent, useState, useEffect } from 'react';
import DialogItem from '../../../../../../common/dialog-item/DialogItem';
import { Divider, Grid } from '@mui/material';
import EmploymentSettingsIcon from '../../../../../../../static/assets/svg/menu/profile-icon.svg';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from '../../../../../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../../../../../slices/companies/companiesSlice';
import { CompanyMember } from '../../../../../../models/member.model';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { Supplier } from '../../../../../../models/supplier.model';
import { Customer } from '../../../../../../models/customer.model';
import { selectUser } from '../../../../../../slices/user/userSlice';

type Data = {
  user: number;
  document_library: number;
  category: string;
  company_id: number;
  secondary_id: number;
};
const ContractGeneratorDialog = (props: {
  open: boolean;
  handleClose: () => void;
  templateTitle: string;
  templateId: number;
  handleGenerateTemplate: (data?: Data) => void;
}) => {
  const { open, handleClose, templateTitle, handleGenerateTemplate, templateId } = props;
  const { t } = useTranslation();
  const company = useAppSelector(selectAllCompanies)[0];
  const currentUser = useAppSelector(selectUser);
  const employees: CompanyMember[] = company?.members || [];
  const employeesFiltered = employees.filter((el) =>
    el.groups.every((item) => item.name !== 'Company admin')
  );
  const employeesSorted = employeesFiltered.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const suppliers: Supplier[] = company?.suppliers || [];
  const suppliersSorted = suppliers.slice().sort((a, b) => a.name.localeCompare(b.name));
  const customers: Customer[] = company?.customers || [];
  const customersSorted = customers
    .slice()
    .sort((a, b) => a.customer_name.localeCompare(b.customer_name));
  const [companyData, setCompanyData] = useState<string>('');
  const [secondPartyCategory, setSecondPartyCategory] = useState<string>('');
  const [secondParty, setSecondParty] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const resetDataOnClose = () => {
    setCompanyData('');
    setSecondParty('');
    setSecondPartyCategory('');
    setStartDate(null);
    setEndDate(null);
  };

  const getSecondParty = () => {
    switch (secondPartyCategory) {
      case 'employment':
        return employeesSorted;
      case 'customers':
        return customersSorted;
      case 'suppliers':
        return suppliersSorted;
      case '':
        return [];
    }
  };

  const getSecondPartyName = (option: {
    first_name?: string;
    last_name?: string;
    name?: string;
    customer_name?: string;
  }) => {
    switch (secondPartyCategory) {
      case 'employment':
        return option.first_name + ' ' + option.last_name;
      case 'customers':
        return option.customer_name;
      case 'suppliers':
        return option.name;
      case '':
        return '';
    }
  };

  const handleSubmitWizard = () => {
    const data: Data = {
      document_library: templateId,
      user: currentUser?.id,
      company_id: company ? company.id : null,
      category: secondPartyCategory,
      secondary_id: secondParty ? Number(secondParty) : null,
    };
    handleGenerateTemplate(data);
  };

  return (
    <>
      <DialogItem
        open={open}
        handleClose={() => {
          resetDataOnClose();
          handleClose();
        }}
        closeIcon={true}
      >
        <Grid>
          <Grid container item className='setting-panel-header ps-0 pe-0'>
            <h4>{t('contractGeneratorDialog.generatorTitle')}</h4>
            <Tooltip title={t('messages.contractGeneratorGeneralMessage')} placement='top'>
              <InfoIcon fontSize='small' color='secondary' />
            </Tooltip>
          </Grid>
          <Divider />
          <Grid
            container
            item
            className='generate-contract-header setting-panel-header flex-nowrap ps-0 pe-0'
            alignItems='flex-start'
          >
            <EmploymentSettingsIcon />
            <h4 className='fw-normal'>{templateTitle}</h4>
          </Grid>
          <div className='setting-panel-body ps-0 pe-0'>
            <ValidatorForm onSubmit={() => handleSubmitWizard()}>
              <Grid container className='form-controls' spacing={4}>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    label={t('labels.companyData')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setCompanyData(e.target.value)}
                    disabled={true}
                    name='companyData'
                    value={company.name}
                    tabIndex={-1}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextValidator
                    select
                    label={t('labels.chooseSecondPartyCategory')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSecondPartyCategory(e.target.value);
                    }}
                    name='secondPartyCategory'
                    value={secondPartyCategory}
                  >
                    <MenuItem value={''}>{t('noSecondPartyCategory')}</MenuItem>
                    <MenuItem value={'employment'}>{t('employees.header')}</MenuItem>
                    <MenuItem value={'customers'}>{t('Customers')}</MenuItem>
                    <MenuItem value={'suppliers'}>{t('documentVault.suppliers')}</MenuItem>
                  </TextValidator>
                </Grid>
                <Grid className='position-relative' item xs={12} md={6}>
                  <Tooltip
                    title={t('messages.selectAUser')}
                    placement='top'
                    style={{ position: 'absolute', right: '0', top: '32px', zIndex: 3 }}
                  >
                    <InfoIcon fontSize='small' color='secondary' />
                  </Tooltip>
                  <TextValidator
                    select
                    label={t('labels.chooseSecondParty')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSecondParty(e.target.value);
                    }}
                    name='employee'
                    value={secondParty}
                  >
                    <MenuItem key={'noSecondParty'} value={''}>
                      {t('contractGeneratorDialog.noSecondParty')}
                    </MenuItem>
                    {getSecondParty().map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {getSecondPartyName(option)}
                      </MenuItem>
                    ))}
                  </TextValidator>
                </Grid>
                {/*<Grid item xs={12} md={6} className='datepicker-container'>
                  <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        aria-labelledby='startDateLabel'
                        value={startDate}
                        format='DD.MM.YYYY'
                        onChange={(newValue) => setStartDate(newValue)}
                        slotProps={{
                          field: { clearable: true },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} className='datepicker-container'>
                  <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        format='DD.MM.YYYY'
                        aria-labelledby='endDateLabel'
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        slotProps={{
                          field: { clearable: true },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>*/}
              </Grid>
              <Grid container className='buttons-row mt-4' justifyContent='flex-end'>
                <Button
                  type='submit'
                  role='button'
                  disabled={false}
                  variant='contained'
                  size='large'
                >
                  {t('buttons.next')}
                </Button>
              </Grid>
            </ValidatorForm>
          </div>
        </Grid>
      </DialogItem>
    </>
  );
};

export default ContractGeneratorDialog;
