import React, { ChangeEvent, useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { NWClient } from '../../../client/NWClient';
import { StyledTableCell } from '../../category/components/TemplatesTable/components/TableCell';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../../common/dialog-item/DialogItem';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants/routes';
import { deleteDocument, selectAllDocuments } from '../../../slices/documents/documentsSlice';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ProgressOverlay } from '../../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectUser } from '../../../slices/user/userSlice';
import { DocumentSigningModel } from '../../../models/document.signing.model';
import AIIcon from '../../../../static/assets/svg/ai-icon.svg';
import Tooltip from '@mui/material/Tooltip';
import DocumentSigningIcon from '../../../../static/assets/svg/menu/documents-signing.svg';
import DownloadIcon from '../../../../static/assets/svg/download-icon.svg';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import PacmanLoader from 'react-spinners/PacmanLoader';
import GenerateTemplateIcon from '../../../../static/assets/svg/generate-template-icon.svg';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export type ActionsCellProps = {
  row: {
    id: number;
    name?: string;
  };
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit';
};

export const ActionsCell = ({ row, align = 'left' }: ActionsCellProps) => {
  const token = localStorage.getItem('access_token');
  const { documentId, category } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUser);
  const location = useLocation();
  const document = useAppSelector(selectAllDocuments).find((el) => el.id === Number(row.id));
  const purposes = [
    {
      title: t('purpose.verification'),
      value: 'verification',
    },
    {
      title: t('purpose.analysis'),
      value: 'analysis',
    },
    {
      title: t('purpose.recordKeeping'),
      value: 'record keeping',
    },
  ];
  const path = location.pathname;
  const [errorText, setErrorText] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [formatMessage, setFormatMessage] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [deleteDocumentMessageVisible, setDeleteDocumentMessageVisible] = useState(false);
  const [aiDocumentDialogVisible, setAiDocumentDialogVisible] = useState(false);
  const [documentToDeleteId, setDocumentToDeleteId] = useState<null | number>(null);
  const [documentTitle, setDocumentTitle] = useState('');
  const [documentType, setDocumentType] = useState('contract');
  const [purpose, setPurpose] = useState(purposes);
  const [resultFormat, setResultFormat] = useState('table');
  const [detailLevel, setDetailLevel] = useState('high level');
  const [simplifyJargon, setSimplifyJargon] = useState(true);
  const [contextualInformation, setContextualInformation] = useState('');
  const [analysisLoading, setAnalysisLoading] = useState<boolean>(false);

  const errorMessageTitle = t('messages.errorOccurred');
  const NoPermissionMessage = () => {
    return (
      <>
        {t('messages.startSubscription')}&nbsp;<Link to={ROUTES.PRICING}>{t('links.here')}</Link>.
      </>
    );
  };
  const noPermissionTitle = t('messages.downloadMessageTitle');

  const handleDownloadLink = (id: number) => {
    setDownloadLoading(true);
    NWClient.downloadDocument(token, id, row.name)
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const handleDelete = (id: number) => {
    setDeleting(true);
    setDeleteDocumentMessageVisible(false);
    setPendingStatus(true);
    if (category === 'templates') {
      NWClient.delete(token, 'document-template', id)
        .then(() => {
          window.location.reload(); //to refactor with redux
        })
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setDeleting(false);
          setDocumentToDeleteId(null);
        });
    } else {
      dispatch(deleteDocument({ token, id }))
        .unwrap()
        .catch((error) => {
          toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
        })
        .finally(() => {
          setDeleting(false);
          setDocumentToDeleteId(null);
          setPendingStatus(false);
        });
    }
  };

  const handleSign = (document_upload: number, documentFormat: string) => {
    if (documentFormat.toLowerCase() === 'pdf') {
      const data = {
        user: currentUser.id,
        document_upload,
        //signing_doc_id: 1, //should be optional later, to refactor
      };
      setPendingStatus(true);
      NWClient.post(token, 'document-sign', data, true)
        .then((res: DocumentSigningModel) => {
          navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
        })
        .finally(() => {
          setPendingStatus(false);
        });
    } else {
      setFormatMessage(true);
    }
  };

  const handleAnalyze = () => {
    setAnalysisLoading(true);
    NWClient.post(
      token,
      'ai-assist',
      {
        document_upload_id: Number(row.id),
        document_type: documentType,
        result_format: resultFormat,
        detail_level: detailLevel,
        purpose: JSON.stringify(purpose.map((el) => el.value)),
        simplify_jargon: simplifyJargon,
        contextual_information: contextualInformation,
        user: currentUser.id,
      },
      true
    )
      .then(() => {
        if (documentId) {
          window.location.href = `${path}?initialTab=0`;
        } else {
          navigate(`${path}/${row.id}?initialTab=0`);
        }
      })
      .catch((error) => {
        setAnalysisLoading(false);
        toast.error(error.message ? error.message : t('messages.errorOccurred'), {
          theme: 'colored',
        });
      });
  };

  const MenuBlock = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div className='d-inline-flex'>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem style={{ padding: 0 }}>
            <Button
              style={{ width: '100%', borderRadius: 0 }}
              onClick={() => {
                navigate(`${path}${documentId ? '' : '/' + row.id}?initialTab=1`);
              }}
              className='templates-table-action'
              aria-label={`edit ${row.name} button`}
              disabled={downloadLoading || deleting}
            >
              {!deleting ? (
                category === 'templates' ? (
                  t('buttons.view')
                ) : (
                  t('buttons.edit')
                )
              ) : (
                <CircularProgress size={20} />
              )}
            </Button>
          </MenuItem>
          {document && document.open_ai_assist_document_upload.length > 0 && (
            <MenuItem style={{ padding: 0 }}>
              <Button
                style={{ width: '100%', borderRadius: 0 }}
                onClick={() => {
                  navigate(`${path}${documentId ? '' : '/' + row.id}?initialTab=0`);
                }}
                className='templates-table-action'
                aria-label={`summary ${row.name} button`}
                disabled={downloadLoading || deleting}
              >
                {!deleting ? t('buttons.summary') : <CircularProgress size={20} />}
              </Button>
            </MenuItem>
          )}
          {category !== 'templates' && (
            <MenuItem style={{ padding: 0 }}>
              <Button
                style={{ width: '100%', borderRadius: 0 }}
                onClick={() => {
                  navigate(`${path}${documentId ? '' : '/' + row.id}?initialTab=2`);
                }}
                className='templates-table-action'
                aria-label={`versions ${row.name} button`}
                disabled={downloadLoading || deleting}
              >
                {!deleting ? t('buttons.versions') : <CircularProgress size={20} />}
              </Button>
            </MenuItem>
          )}
          {category !== 'templates' && (
            <MenuItem style={{ padding: 0 }}>
              <Button
                style={{ width: '100%', borderRadius: 0 }}
                onClick={() => {
                  navigate(`${path}${documentId ? '' : '/' + row.id}?initialTab=3`);
                }}
                className='templates-table-action'
                aria-label={`connections ${row.name} button`}
                disabled={downloadLoading || deleting}
              >
                {!deleting ? t('buttons.connections') : <CircularProgress size={20} />}
              </Button>
            </MenuItem>
          )}
          <MenuItem style={{ padding: 0 }}>
            <Button
              style={{ width: '100%', borderRadius: 0 }}
              onClick={() => {
                setDocumentToDeleteId(row.id);
                setDeleteDocumentMessageVisible(true);
              }}
              className='templates-table-action'
              aria-label={`delete ${row.name} button`}
              disabled={downloadLoading || deleting}
            >
              {!deleting ? t('buttons.delete') : <CircularProgress size={20} />}
            </Button>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <>
      <StyledTableCell align={align}>
        <Grid container justifyContent='flex-end'>
          <Grid
            item
            xs={12}
            className='d-flex align-items-center justify-content-end position-relative'
          >
            {category !== 'templates' && (
              <>
                {/*<Tooltip title={t('messages.AITemplateCreatMessage')} placement='top' arrow>
                  <IconButton
                    className='table-row-icon-button'
                    onClick={() => {
                      setAnalysisLoading(true);
                      navigate(`${path}/${row.id}?initialTab=4`);
                    }}
                    disabled={downloadLoading || deleting}
                  >
                    <GenerateTemplateIcon />
                  </IconButton>
                </Tooltip>*/}
                <Tooltip title={t('messages.AISummaryMessage')} placement='top' arrow>
                  <IconButton
                    className='table-row-icon-button'
                    onClick={() => {
                      setAiDocumentDialogVisible(true);
                      setDocumentTitle(row.name);
                    }}
                    disabled={downloadLoading || deleting}
                  >
                    <AIIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('buttons.download')} placement='top' arrow>
                  <IconButton
                    className='table-row-icon-button'
                    onClick={() => {
                      handleDownloadLink(row.id);
                    }}
                    disabled={downloadLoading || deleting}
                    aria-label={`download ${row.name} button`}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('buttons.sign')} placement='top' arrow>
                  <IconButton
                    className='table-row-icon-button'
                    onClick={() => {
                      handleSign(
                        Number(row.id),
                        row.name.substring(row.name.length - 3, row.name.length)
                      );
                    }}
                    aria-label={`sign ${row.name} button`}
                    disabled={downloadLoading || deleting}
                  >
                    <DocumentSigningIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {documentId && (
              <Tooltip
                title={category === 'templates' ? t('buttons.view') : t('buttons.edit')}
                placement='top'
                arrow
              >
                <IconButton
                  className='table-row-icon-button'
                  onClick={() => {
                    navigate(`${path}${documentId ? '' : '/' + row.id}?initialTab=1`);
                  }}
                  aria-label={`edit ${row.name} button`}
                  disabled={downloadLoading}
                >
                  <ModeEditOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {!documentId && <MenuBlock />}
          </Grid>
        </Grid>
        <DialogItem
          isErrorMessage={false}
          open={formatMessage}
          title={null}
          text={t('messages.pdfMessage')}
          handleClose={() => setFormatMessage(false)}
        />
        <DialogItem
          isErrorMessage={errorState && errorStatus !== 403}
          open={errorState}
          title={errorStatus !== 403 ? errorMessageTitle : noPermissionTitle}
          text={errorText}
          handleClose={() => setErrorState(false)}
        >
          {errorStatus === 403 ? <NoPermissionMessage /> : null}
        </DialogItem>
        <DialogItem
          isErrorMessage={false}
          open={deleteDocumentMessageVisible}
          title={
            deleteDocumentMessageVisible
              ? t('dialogTitles.deleteFile')
              : t('dialogTitles.deleteDefault')
          }
          text={
            deleteDocumentMessageVisible ? t('messages.deleteFile') : t('messages.deleteDefault')
          }
          noDefaultActionsRow={true}
          handleClose={() => {
            setDeleteDocumentMessageVisible(false);
          }}
        >
          <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
            <Button
              onClick={() => handleDelete(documentToDeleteId)}
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='outlined'
              size='medium'
            >
              {t('buttons.deleteFile')}
            </Button>
            <Button
              type='button'
              role='button'
              variant='contained'
              size='medium'
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => {
                setDeleteDocumentMessageVisible(false);
                setDocumentToDeleteId(null);
              }}
            >
              {t('buttons.goBack')}
            </Button>
          </div>
        </DialogItem>
        {aiDocumentDialogVisible && (
          <DialogItem
            isErrorMessage={false}
            open={aiDocumentDialogVisible}
            title={documentTitle}
            noDefaultActionsRow={true}
            headerIcon={<AIIcon />}
            handleClose={() => {
              setAiDocumentDialogVisible(false);
            }}
          >
            <div className='d-flex mb-3'>
              <p className='text-center flex-grow-1 ps-2 pe-2'>{t('messages.reviewSettings')}</p>
              <Tooltip
                className='flex-shrink-0'
                title={t('messages.AIFunctionalityMessage')}
                placement='top'
              >
                <InfoIcon fontSize='small' color='secondary' />
              </Tooltip>
            </div>
            <hr />
            <Grid>
              <div className='setting-panel-body ps-0 pe-0'>
                <ValidatorForm onSubmit={() => console.log('')}>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} className='pt-3' md={6}>
                      <InputLabel htmlFor='documentTypeSelect'>
                        {t('labels.documentType')}
                      </InputLabel>
                      <Select
                        className='w-100'
                        id='documentTypeSelect'
                        onChange={(e: SelectChangeEvent<typeof documentType>) =>
                          setDocumentType(e.target.value)
                        }
                        name='documentType'
                        value={documentType}
                        tabIndex={-1}
                        defaultOpen={true}
                      >
                        <MenuItem value='contract'>{t('documentTypes.contract')}</MenuItem>
                        <MenuItem value='insurance'>{t('documentTypes.insurance')}</MenuItem>
                        <MenuItem value='invoice'>{t('documentTypes.invoice')}</MenuItem>
                        <MenuItem value='certificate'>{t('documentTypes.certificate')}</MenuItem>
                        <MenuItem value='other'>{t('documentTypes.other')}</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} className='pt-3' md={6}>
                      <Autocomplete
                        className='mt-0'
                        multiple
                        id='tags-standard'
                        openOnFocus={true}
                        options={purposes}
                        disableCloseOnSelect={true}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        value={purpose}
                        onChange={(
                          event: ChangeEvent<HTMLInputElement>,
                          newValue:
                            | {
                                title: string;
                                value: string;
                              }[]
                            | null
                        ) => {
                          setPurpose(newValue);
                        }}
                        renderOption={(props, option, { selected }) => {
                          const { ...optionProps } = props;
                          return (
                            <li key={option.value} {...optionProps}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.title}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant='standard' label={t('labels.purpose')} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} className='pt-3' md={6}>
                      <TextValidator
                        select
                        label={t('labels.resultFormat')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setResultFormat(e.target.value);
                        }}
                        name='resultFormat'
                        value={resultFormat}
                      >
                        <MenuItem value={''}>{''}</MenuItem>
                        <MenuItem value='table'>{t('resultFormat.table')}</MenuItem>
                        <MenuItem value='bullet point'>{t('resultFormat.bulletPoint')}</MenuItem>
                        <MenuItem value='narrative format'>
                          {t('resultFormat.narrativeFormat')}
                        </MenuItem>
                      </TextValidator>
                    </Grid>
                    <Grid item xs={12} className='pt-3' md={6}>
                      <TextValidator
                        select
                        label={t('labels.detailLevel')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setDetailLevel(e.target.value);
                        }}
                        name='detailLevel'
                        value={detailLevel}
                      >
                        <MenuItem value='high level'>{t('detailLevel.highLevel')}</MenuItem>
                        <MenuItem value='detailed'>{t('detailLevel.detailed')}</MenuItem>
                      </TextValidator>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className='pt-3 checkbox-row d-flex align-items-start'
                    >
                      <Checkbox
                        style={{ marginLeft: -10 }}
                        id='simplifyTechnicalOrLegalJargon'
                        checked={simplifyJargon}
                        onChange={(event) => setSimplifyJargon(event.target.checked)}
                      />
                      <label htmlFor='simplifyTechnicalOrLegalJargon'>
                        {t('labels.simplifyJargon')}
                      </label>
                    </Grid>
                    <Grid item xs={12} className='pt-3' md={12}>
                      <InputLabel htmlFor='contextualInformation'>
                        {t('labels.contextualInformation')}
                      </InputLabel>
                      <textarea
                        id='contextualInformation'
                        name='contextualInformation'
                        value={contextualInformation}
                        placeholder={t('messages.contextualInformationPlaceholder')}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                          setContextualInformation(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className='pt-0 buttons-row d-flex flex-wrap justify-content-end'
                    >
                      <hr className='w-100 mb-3' />
                      <Button
                        type='button'
                        role='button'
                        variant='outlined'
                        size='medium'
                        style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                        onClick={() => {
                          setAiDocumentDialogVisible(false);
                        }}
                      >
                        {t('buttons.goBack')}
                      </Button>
                      <Button
                        onClick={() => handleAnalyze()}
                        style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                        type='button'
                        role='button'
                        variant='contained'
                        size='medium'
                      >
                        {t('buttons.analyze')}
                      </Button>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </div>
            </Grid>
          </DialogItem>
        )}
        {pendingStatus ? <ProgressOverlay /> : null}
        {analysisLoading ? (
          <div
            className='position-fixed w-100 vh-100 d-flex align-items-center justify-content-center'
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 3000, left: 0, top: 0 }}
          >
            <PacmanLoader loading={analysisLoading} color='#6414DB' />
          </div>
        ) : null}
      </StyledTableCell>
    </>
  );
};
