import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SettingsMenu } from './components/SettingsMenu';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { fetchDocuments, selectAllDocuments } from '../../slices/documents/documentsSlice';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { DocumentContentTab } from './components/tabs/DocumentContentTab';
import { DocumentSummaryTab } from './components/tabs/DocumentSummaryTab';
import { DocumentVersionsTab } from './components/tabs/DocumentVersionsTab';
import { DocumentConnectionsTab } from './components/tabs/DocumentConnectionsTab';
import { DocumentTemplateTab } from './components/tabs/DocumentTemplateTab';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { NWClient } from '../../client/NWClient';
import { AIAssistModel } from '../../models/ai.assist.model';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { toast } from 'react-toastify';
import { DocumentHtmlModel } from '../../models/document.html.model';
import { DocumentTemplateModel } from '../../models/document.template.model';
import { AIDocumentContractModel } from '../../models/ai.document.contract.model';
import {
  fetchDocumentContracts,
  selectAllDocumentContracts,
} from '../../slices/document-contracts/documentContractsSlice';
import { selectUser } from '../../slices/user/userSlice';

const DocumentItemPage = () => {
  const { documentId, category } = useParams();
  const [params] = useSearchParams();
  const tab = params.get('initialTab');
  const contractGenerated = params.get('contractGenerated');
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUser);
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();
  const token = localStorage.getItem('access_token');
  const documentsArray = useAppSelector(selectAllDocuments);
  const documentContractsArray = useAppSelector(selectAllDocumentContracts);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [summary, setSummary] = useState('');
  const [documentHTML, setDocumentHTML] = useState('');
  const [documentItem, setDocumentItem] = useState<DocumentVaultItemModel | null>(null);
  const [templateItem, setTemplateItem] = useState<DocumentTemplateModel | null>(null);
  const [currentTab, setCurrentTab] = useState(tab ? Number(tab) : 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    navigate(`${path}?initialTab=${newValue}`);
  };

  const getItemName = () => {
    if (documentItem) {
      return documentItem.name;
    } else if (templateItem) {
      return (
        documentsArray.find((el) => el.id === templateItem.document_upload)?.name +
        `_${templateItem.updated}`
      );
    } else if (contractGenerated === 'true') {
      return documentContractsArray?.find((el) => el.id === Number(documentId))?.name;
    } else {
      return t('documentPage.headerDefault');
    }
  };

  useEffect(() => {
    dispatch(fetchDocuments(token));
    if (category === 'templates') {
      NWClient.get(token, 'document-template', documentId).then((res: DocumentTemplateModel) => {
        setTemplateItem(res);
        setDocumentHTML(res.content);
        setLoading(false);
      });
    } else if (contractGenerated === 'true') {
      dispatch(fetchDocumentContracts({ token, data: { user: currentUser.id } }));
      NWClient.get(token, 'document-contract', documentId).then((res: AIDocumentContractModel) => {
        setDocumentHTML(res.html);
        setLoading(false);
      });
    } else {
      NWClient.get(token, 'document-upload', documentId).then((res: DocumentVaultItemModel) => {
        setDocumentItem(res);
      });
    }
  }, []);

  useEffect(() => {
    if (documentItem) {
      NWClient.get(token, 'ai-assist', Math.max(...documentItem.open_ai_assist_document_upload))
        .then((res: AIAssistModel) => {
          setSummary(res.content);
          NWClient.get(
            token,
            'document-upload-html',
            Math.max(...documentItem.document_upload_html)
          )
            .then((res: DocumentHtmlModel) => {
              setDocumentHTML(res.html);
            })
            .catch((error) => {
              if (error.response && error.response.status !== 404) {
                toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
              }
            })
            .finally(() => setLoading(false));
        })
        .catch((error) => {
          if (error.response && error.response.status !== 404) {
            toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
          } else {
            NWClient.get(
              token,
              'document-upload-html',
              Math.max(...documentItem.document_upload_html)
            )
              .then((res: DocumentHtmlModel) => {
                setDocumentHTML(res.html);
              })
              .catch((error) => {
                if (error.response && error.response.status !== 404) {
                  toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
                }
              })
              .finally(() => setLoading(false));
          }
        });
    }
  }, [documentItem]);

  useEffect(() => {
    if (tab) {
      setCurrentTab(Number(tab));
    }
  }, [tab]);

  return (
    <>
      <h1 className='mb-4'>{getItemName()}</h1>
      <Grid container spacing={4}>
        {category !== 'templates' && contractGenerated !== 'true' && (
          <Grid item xs={12} md={2}>
            <SettingsMenu value={currentTab} handleChange={handleChange} />
          </Grid>
        )}
        <Grid item xs={12} md={category !== 'templates' && contractGenerated !== 'true' ? 10 : 12}>
          <div className='setting-panel mb-5'>
            {currentTab === 0 && category !== 'templates' && contractGenerated !== 'true' && (
              <DocumentSummaryTab currentTab={currentTab} summary={summary} />
            )}
            {(currentTab === 1 || category === 'templates' || contractGenerated !== 'true') && (
              <DocumentContentTab
                currentTab={currentTab}
                documentUploadId={templateItem ? templateItem.document_upload : null}
                documentName={documentItem ? documentItem.name : ''}
                htmlContent={documentHTML}
              />
            )}

            {currentTab === 2 && category !== 'templates' && contractGenerated !== 'true' && (
              <DocumentVersionsTab
                documentName={documentItem ? documentItem.name : ''}
                currentTab={currentTab}
              />
            )}
            {category !== 'templates' && contractGenerated !== 'true' && (
              <DocumentConnectionsTab currentTab={currentTab} />
            )}
            {/*currentTab === 4 && category !== 'templates' && contractGenerated !== 'true' && (
              <DocumentTemplateTab
                currentTab={currentTab}
                documentName={documentItem ? documentItem.name : ''}
                htmlContent={documentHTML}
                documentUploadHtmlId={
                  documentItem ? Math.max(...documentItem.document_upload_html) : null
                }
              />
            )*/}
          </div>
        </Grid>
      </Grid>
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};
export default DocumentItemPage;
