import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NWClient } from '../../../client/NWClient';
import { Category } from '../../../models/category.model';
import {
  fetchCategories,
  selectAllCategories,
  selectCategoryByName,
} from '../../../slices/categories/categoriesSlice';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export const useCategory = () => {
  const { name } = useParams();
  const token = localStorage.getItem('access_token');
  const [category, setCategory] = useState<Category>(null);
  const [fetchCategoriesDispatched, setFetchCategoriesDispatched] = useState<boolean>(false);
  //const [categories, setCategories] = useState<Category[]>([]);
  const categories = useSelector(selectAllCategories);
  const categoriesCopy = [...categories];
  const groupedCategories = () => {
    const groupedItems: { name: string; id: number; className: string }[] = [];
    for (const item of categoriesCopy) {
      if (item.parent === null) {
        const el = { name: item.name, id: item.id, className: 'level-1' };
        groupedItems.push(el);
        if (item.sub_categories && item.sub_categories.length > 0) {
          for (const cl2 of item.sub_categories) {
            const cl2Item = { name: cl2.name, id: cl2.id, className: 'level-2' };
            groupedItems.push(cl2Item);
            for (const cl3 of categories.filter((el) => el.parent === cl2.id)) {
              const cl3Item = { name: cl3.name, id: cl3.id, className: 'level-3' };
              groupedItems.push(cl3Item);
            }
          }
        }
      }
    }
    return groupedItems;
  };
  const dispatch = useAppDispatch();
  const categoryItem = useSelector((state: RootState) => selectCategoryByName(state, name));
  useEffect(() => {
    if (token && categories.length === 0 && !fetchCategoriesDispatched) {
      setFetchCategoriesDispatched(true);
      dispatch(fetchCategories(token));
    }
  }, [token, categories, dispatch, fetchCategoriesDispatched]);
  useEffect(() => {
    if (categoryItem) {
      const parent = categories.find((mappedCategory) => categoryItem.parent === mappedCategory.id);

      NWClient.get<Category>(token, 'category', categoryItem.id).then((category: Category) => {
        setCategory({ ...category, parentName: parent?.name.toLowerCase() });
      });
    }
    /*NWClient.list<Category>(token, 'category').then((categories) => {
      //setCategories(categories);
      const category = categories.find((category) => category.name === name);

      if (category) {
        const parent = categories.find((mappedCategory) => category.parent === mappedCategory.id);

        NWClient.get<Category>(token, 'category', category.id).then((category: Category) => {
          setCategory({ ...category, parentName: parent?.name.toLowerCase() });
        });
      }
    });*/
  }, [name, token, categories, categoryItem]);

  return { searchCategories: groupedCategories(), category, name };
};
