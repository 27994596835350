import React, { ChangeEvent, useState, useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import { Avatar, CircularProgress, Divider, FormHelperText, Grid } from '@mui/material';
import EmploymentSettingsIcon from '../../../static/assets/svg/menu/profile-icon.svg';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import dayjs, { Dayjs } from 'dayjs';
import { fetchCompanies, selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useParams } from 'react-router-dom';
import QualificationsIcon from '../../../static/assets/svg/menu/qualifications.svg';
import EmergencySettingsIcon from '../../../static/assets/svg/menu/emergency.svg';
import { BLACK_COLOR, LIGHT_GREY } from '../../common/constants/colors';
import { RELATIONSHIP } from '../../common/constants/relationship';
import { POSITION_TYPES } from '../../common/constants/position_types';
import { NWClient } from '../../client/NWClient';
import { toast } from 'react-toastify';
import 'dayjs/locale/en-gb';
import { Company } from '../../models/company.model';

const EmployeeProfilePageForAdmin = () => {
  const { userId } = useParams();
  const company = useAppSelector(selectAllCompanies)[0];
  const employee = company?.members.find((el) => el.id === Number(userId));
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('access_token');
  const [pendingStatus, setPendingStatus] = useState(false);
  const [email, setEmail] = useState<string | null>('');
  const [address, setAddress] = useState<string | null>('');
  const [phoneNumber, setPhoneNumber] = useState<string | null>('');
  const [positionTitle, setPositionTitle] = useState<string>('');
  const [positionType, setPositionType] = useState<string | null>('');
  const [department, setDepartment] = useState<string | null>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [emailBusiness, setEmailBusiness] = useState<string | null>('');
  const [taxNumber, setTaxNumber] = useState<string | null>('');
  const [employeeId, setEmployeeId] = useState<string | null>('');
  const [positionDescription, setPositionDescription] = useState<string | null>('');
  const [degree, setDegree] = useState<string>('');
  const [universityName, setUniversityName] = useState<string | null>('');
  const [startDateQualifications, setStartDateQualifications] = useState<Dayjs | null>(null);
  const [endDateQualifications, setEndDateQualifications] = useState<Dayjs | null>(null);
  const [firstNameEmergency, setFirstNameEmergency] = useState<string>('');
  const [lastNameEmergency, setLastNameEmergency] = useState<string | null>('');
  const [emailEmergency, setEmailEmergency] = useState<string | null>('');
  const [addressEmergency, setAddressEmergency] = useState<string | null>('');
  const [phoneNumberEmergency, setPhoneNumberEmergency] = useState<string | null>('');
  const [relationship, setRelationship] = useState<string>('');
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const canSubmit = true;
  const submitEmployment = (company: Company) => {
    const currentUser = company ? company.members.find((el) => el.id === Number(userId)) : null;
    const data = {
      position_title: positionTitle,
      position_description: positionDescription,
      position_type: positionType,
      department: department,
      employee_tax_number: taxNumber,
      employee_id: employeeId,
      start_date: startDate ? startDate.toISOString().substring(0, 10) : null,
      end_date: endDate ? endDate.toISOString().substring(0, 10) : null,
      employee_details_id: currentUser.details ? currentUser.details.id : null,
    };
    if (currentUser.details.employments.length === 0) {
      NWClient.post(token, `employee_details/${currentUser.details.id}/employments`, data, true)
        .then(() => {
          submitQualification(company);
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    } else {
      NWClient.put(
        token,
        `employee_details/${currentUser.details.id}/employments`,
        currentUser.details.employments[0].id,
        data,
        true,
        true
      )
        .then(() => {
          submitQualification(company);
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };
  const submitEmergencyContact = (company: Company) => {
    const currentUser = company ? company.members.find((el) => el.id === Number(userId)) : null;
    const data = {
      first_name: firstNameEmergency,
      last_name: lastNameEmergency,
      postal_address: addressEmergency,
      email_address: emailEmergency,
      relationship,
      phone_number: phoneNumberEmergency,
      employee_details_id: currentUser.details ? currentUser.details.id : null,
    };
    if (!currentUser.details.emergency_contact) {
      NWClient.post(
        token,
        `employee_details/${currentUser.details.id}/emergency_contact`,
        data,
        true
      )
        .then(() => {
          dispatch(fetchCompanies(token)); //to refactor
          setPendingStatus(false);
          setFieldsErrors(null);
          toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
        })
        .catch((error) => {
          setPendingStatus(false);
          setFieldsErrors(error.response.data);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    } else {
      NWClient.put(
        token,
        `employee_details/${currentUser.details.id}/emergency_contact`,
        currentUser.details.emergency_contact.id,
        data,
        true,
        true
      )
        .then(() => {
          dispatch(fetchCompanies(token)); //to refactor
          setPendingStatus(false);
          setFieldsErrors(null);
          toast.success(t('messages.userDataUpdated'), { theme: 'colored' });
        })
        .catch((error) => {
          setPendingStatus(false);
          setFieldsErrors(error.response.data);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };

  const submitQualification = (company: Company) => {
    const currentUser = company ? company.members.find((el) => el.id === Number(userId)) : null;
    const data = {
      degree,
      university_name: universityName,
      start_date: startDateQualifications
        ? startDateQualifications.toISOString().substring(0, 10)
        : null,
      end_date: endDateQualifications ? endDateQualifications.toISOString().substring(0, 10) : null,
      employee_details_id: currentUser.details ? currentUser.details.id : null,
    };
    if (currentUser.details.qualifications.length === 0) {
      NWClient.post(token, `employee_details/${currentUser.details.id}/qualifications`, data, true)
        .then(() => {
          submitEmergencyContact(company);
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    } else {
      NWClient.put(
        token,
        `employee_details/${currentUser.details.id}/qualifications`,
        currentUser.details.qualifications[0].id,
        data,
        true,
        true
      )
        .then(() => {
          submitEmergencyContact(company);
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const currentUser = company ? company.members.find((el) => el.id === Number(userId)) : null;
    const data = {
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      address,
    };
    const dataDetails = {
      /*postal_code: postalCode,*/
      /*country,*/
      contact_number: phoneNumber,
      /*city: cityCounty,*/
      user: currentUser.id,
      /*gender,*/
      email_address: emailBusiness,
    };
    NWClient.put(token, 'user/update_details', currentUser.id, data)
      .then(() => {
        currentUser.details && currentUser.details.user
          ? NWClient.put(token, 'employee_details', currentUser.details.id, dataDetails, true)
              .then(() => {
                submitEmployment(company);
              })
              .catch((error) => {
                setFieldsErrors(error.response.data);
                setPendingStatus(false);
                toast.error(t('messages.errorOccurred'), { theme: 'colored' });
              })
          : NWClient.post(token, 'employee_details', dataDetails)
              .then(() => {
                dispatch(fetchCompanies(token))
                  .unwrap()
                  .then((res: Company[]) => {
                    submitEmployment(res[0]);
                  });
              })
              .catch((error) => {
                setFieldsErrors(error.response.data);
                setPendingStatus(false);
                toast.error(t('messages.errorOccurred'), { theme: 'colored' });
              });
      })
      .catch((error) => {
        setFieldsErrors(error.response.data);
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  const setDetails = () => {
    const currentUser = company ? company.members.find((el) => el.id === Number(userId)) : null;
    const userDetails = currentUser && currentUser.details;
    if (currentUser) {
      setEmail(currentUser.email);
      setAddress(currentUser.address);
    }
    if (userDetails) {
      setPhoneNumber(userDetails.contact_number);
      setEmailBusiness(userDetails.email_address);
      if (userDetails.employments.length > 0) {
        const employmentInstance = userDetails.employments[0];
        setPositionTitle(employmentInstance.position_title);
        setDepartment(employmentInstance.department);
        setEmployeeId(employmentInstance.employee_id);
        setTaxNumber(employmentInstance.employee_tax_number);
        setPositionType(employmentInstance.position_type);
        setPositionDescription(employmentInstance.position_description);
        setStartDate(
          employmentInstance.start_date ? dayjs(new Date(employmentInstance.start_date)) : null
        );
        setEndDate(
          employmentInstance.end_date ? dayjs(new Date(employmentInstance.end_date)) : null
        );
      }
      if (userDetails.qualifications.length > 0) {
        const qualificationsInstance = userDetails.qualifications[0];
        setDegree(qualificationsInstance.degree);
        setUniversityName(qualificationsInstance.university_name);
        setStartDateQualifications(
          qualificationsInstance.start_date
            ? dayjs(new Date(qualificationsInstance.start_date))
            : null
        );
        setEndDateQualifications(
          qualificationsInstance.end_date ? dayjs(new Date(qualificationsInstance.end_date)) : null
        );
      }
      if (userDetails.emergency_contact) {
        const emergencyContactInstance = userDetails.emergency_contact;
        setFirstNameEmergency(emergencyContactInstance.first_name);
        setLastNameEmergency(emergencyContactInstance.last_name);
        setEmailEmergency(emergencyContactInstance.email_address);
        setAddressEmergency(emergencyContactInstance.postal_address);
        setRelationship(emergencyContactInstance.relationship);
        setPhoneNumberEmergency(emergencyContactInstance.phone_number);
      }
    }
  };

  const positionTypeArray = POSITION_TYPES.map((el) => t(el));
  const relationshipArray = RELATIONSHIP.map((el) => t(el));

  useEffect(() => {
    if (company) {
      setDetails();
      setLoading(false);
    } else {
      dispatch(fetchCompanies(token))
        .then(() => {
          setDetails();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company, dispatch, token]);
  return (
    <>
      {loading ? (
        <div className='circular-progress-container'>
          <CircularProgress size={60} />
        </div>
      ) : (
        <div className='profile-details-wrapper'>
          <Grid>
            <div>
              <ValidatorForm onSubmit={handleSubmit}>
                <div className='basic-info'>
                  <Grid container item className='setting-panel-header' alignItems='center'>
                    <div className='invite-employees-item-avatar d-flex justify-content-center'>
                      <Avatar
                        sx={{
                          width: 80,
                          height: 80,
                          marginRight: '1rem',
                          fontSize: '2rem',
                          backgroundColor: LIGHT_GREY,
                          color: BLACK_COLOR,
                        }}
                      >{`${employee ? employee.first_name[0] : null}${
                        employee ? employee.last_name[0] : null
                      }`}</Avatar>
                    </div>
                    <h2>{`${employee ? employee.first_name + ' ' : ''}${
                      employee && employee.middle_name ? employee.middle_name + ' ' : ''
                    }${employee ? employee.last_name : ''}`}</h2>
                    <Button
                      style={{ marginLeft: 'auto' }}
                      variant='outlined'
                      size='large'
                      onClick={() => {
                        if (editMode) {
                          dispatch(fetchCompanies(token));
                          setEditMode(false);
                        } else {
                          setEditMode(true);
                        }
                      }}
                    >
                      {!editMode ? t('buttons.edit') : t('buttons.stopEditing')}
                    </Button>
                  </Grid>
                  <Divider />
                  <div className='setting-panel-body'>
                    <Grid container className='form-controls' spacing={4}>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.email')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                          name='email'
                          value={email}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.businessEmailAddress')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setEmailBusiness(e.target.value)
                          }
                          name='emailBusiness'
                          value={emailBusiness}
                          disabled={!editMode}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.phoneNumber')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setPhoneNumber(e.target.value)
                          }
                          name='phoneNumber'
                          disabled={!editMode}
                          value={phoneNumber}
                        />
                        {Boolean(fieldsErrors?.contact_number) && (
                          <FormHelperText error={true}>
                            {fieldsErrors?.contact_number.join('.')}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.postalAddress')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setAddress(e.target.value);
                          }}
                          disabled={!editMode}
                          name='address'
                          value={address}
                        />
                        {Boolean(fieldsErrors?.address) && (
                          <FormHelperText error={true}>
                            {fieldsErrors?.address.join('.')}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Grid container item className='setting-panel-header' alignItems='center'>
                  <EmploymentSettingsIcon />
                  <h3>{t('settings.titles.employment')}</h3>
                </Grid>
                <Divider />
                <div className='setting-panel-body'>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.employeeId')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setEmployeeId(e.target.value)
                        }
                        name='employeeId'
                        value={employeeId}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.employeeTaxNumber')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setTaxNumber(e.target.value)
                        }
                        name='employeeTaxNumber'
                        value={taxNumber}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.positionTitle')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPositionTitle(e.target.value)
                        }
                        name='positionTitle'
                        value={positionTitle}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.department')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setDepartment(e.target.value)
                        }
                        name='department'
                        value={department}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        select
                        label={t('labels.positionType')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPositionType(e.target.value)
                        }
                        disabled={!editMode}
                        name='positionType'
                        value={positionType}
                      >
                        {positionTypeArray.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextValidator>
                    </Grid>
                    <Grid item xs={12} md={6} className='datepicker-container'>
                      <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            disabled={!editMode}
                            aria-labelledby='startDateLabel'
                            value={startDate}
                            format='DD.MM.YYYY'
                            onChange={(newValue) => setStartDate(newValue)}
                            slotProps={{
                              field: { clearable: true },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} className='datepicker-container'>
                      <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            disabled={!editMode}
                            format='DD.MM.YYYY'
                            aria-labelledby='endDateLabel'
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            slotProps={{
                              field: { clearable: true },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor='positionDescriptionLabel'>
                        {t('labels.positionDescription')}
                      </InputLabel>
                      <textarea
                        id='positionDescription'
                        name='positionDescription'
                        disabled={!editMode}
                        value={positionDescription}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                          setPositionDescription(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
                <Grid container item className='setting-panel-header' alignItems='center'>
                  <QualificationsIcon />
                  <h3>{t('settings.titles.qualifications')}</h3>
                </Grid>
                <Divider />
                <div className='setting-panel-body'>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.degreeOrCertificate')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setDegree(e.target.value)}
                        disabled={!editMode}
                        name='degree'
                        value={degree}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.institutionName')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setUniversityName(e.target.value)
                        }
                        disabled={!editMode}
                        name='universityName'
                        value={universityName}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className='datepicker-container'>
                      <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            disabled={!editMode}
                            aria-labelledby='startDateLabel'
                            value={startDateQualifications}
                            format='DD.MM.YYYY'
                            onChange={(newValue) => setStartDateQualifications(newValue)}
                            slotProps={{
                              field: { clearable: true },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} className='datepicker-container'>
                      <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            disabled={!editMode}
                            aria-labelledby='endDateLabel'
                            format='DD.MM.YYYY'
                            value={endDateQualifications}
                            onChange={(newValue) => setEndDateQualifications(newValue)}
                            slotProps={{
                              field: { clearable: true },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </div>
                <Grid container item className='setting-panel-header' alignItems='center'>
                  <EmergencySettingsIcon />
                  <h3>{t('settings.titles.emergencyContact')}</h3>
                </Grid>
                <Divider />
                <div className='setting-panel-body'>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.firstName')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFirstNameEmergency(e.target.value)
                        }
                        name='firstNameEmergency'
                        disabled={!editMode}
                        value={firstNameEmergency}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.lastName')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setLastNameEmergency(e.target.value)
                        }
                        name='lastNameEmergency'
                        disabled={!editMode}
                        value={lastNameEmergency}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.email')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setEmailEmergency(e.target.value)
                        }
                        name='emailEmergency'
                        disabled={!editMode}
                        value={emailEmergency}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.phoneNumber')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPhoneNumberEmergency(e.target.value)
                        }
                        disabled={!editMode}
                        name='phoneNumberEmergency'
                        value={phoneNumberEmergency}
                      />
                      {Boolean(fieldsErrors?.phone_number) && (
                        <FormHelperText error={true}>
                          {fieldsErrors?.phone_number.join('.')}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        label={t('labels.postalAddress')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setAddressEmergency(e.target.value);
                        }}
                        disabled={!editMode}
                        name='addressEmergency'
                        value={addressEmergency}
                      />
                      {Boolean(fieldsErrors?.postal_address) && (
                        <FormHelperText error={true}>
                          {fieldsErrors?.postal_address.join('.')}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        select
                        label={t('labels.relationship')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setRelationship(e.target.value)
                        }
                        disabled={!editMode}
                        name='relationship'
                        value={relationship}
                      >
                        {relationshipArray.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextValidator>
                    </Grid>
                  </Grid>
                </div>
                {editMode && (
                  <Grid container className='buttons-row pb-5' justifyContent='flex-end'>
                    <Button
                      type='submit'
                      role='button'
                      disabled={!canSubmit}
                      variant='contained'
                      size='large'
                    >
                      Update
                    </Button>
                  </Grid>
                )}
                {pendingStatus ? <ProgressOverlay /> : ''}
              </ValidatorForm>
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default EmployeeProfilePageForAdmin;
