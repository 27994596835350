import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { CircularProgress, Divider, FormHelperText, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectUserRoleByGroupName } from '../../../../slices/user/userSlice';
import { GROUPS } from '../../../../common/constants/groups';
import CompanySettingsIcon from '../../../../../static/assets/svg/menu/business-icon.svg';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import MailIcon from '../../../../../static/assets/svg/menu/mail-icon.svg';
import MenuItem from '@mui/material/MenuItem';
import LocationIcon from '../../../../../static/assets/svg/menu/address-location-icon.svg';
import { Country } from 'country-state-city';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';

export const CompanyDetailsTab = ({ currentTab }: TabProps) => {
  const token = localStorage.getItem('access_token');
  const onlyDigitSpaceRegExp = useMemo(() => {
    return /^[0-9 ]*$/;
  }, []);
  const phoneRegExp = useMemo(() => {
    return /^\+?[\s\d]*$/;
    //return /^\+\d*$/;
  }, []);

  const [companyId, setCompanyId] = useState<number>(null);
  const [businessName, setBusinessName] = useState<string>('');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState<string>('');
  const [businessABNorACN, setBusinessABNorACN] = useState<string>('');
  const [amountOfEmployees, setAmountOfEmployees] = useState<string>();
  const [pendingStatus, setPendingStatus] = useState(false);
  const [postalAddress, setPostalAddress] = useState<string | null>('');
  const [postalCode, setPostalCode] = useState<string | null>('');
  const [postalCity, setPostalCity] = useState<string | null>('');
  const [postalCountry, setPostalCountry] = useState<string | null>('');
  const [physicalAddress, setPhysicalAddress] = useState<string | null>('');
  const [physicalCode, setPhysicalCode] = useState<string | null>('');
  const [physicalCity, setPhysicalCity] = useState<string | null>('');
  const [physicalCountry, setPhysicalCountry] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const { t } = useTranslation();
  const canSubmit = Boolean(businessName);
  const countries = Country.getAllCountries();
  const isEmployee = Boolean(useSelector(selectUserRoleByGroupName(GROUPS.COMPANY_EMPLOYEE)));

  useEffect(() => {
    ValidatorForm.addValidationRule('ABNorACNLength', (value) => {
      return (
        onlyDigitSpaceRegExp.test(value.trim()) &&
        (value.trim().replace(/\s/g, '').length === 11 ||
          value.trim().replace(/\s/g, '').length === 9 ||
          value.trim().length === 0)
      );
    });
    return () => {
      ValidatorForm.removeValidationRule('ABNorACNLength');
    };
  }, [businessABNorACN, onlyDigitSpaceRegExp]);

  useEffect(() => {
    ValidatorForm.addValidationRule('phone', (value) => {
      return (
        (phoneRegExp.test(value.trim()) || value === '') &&
        value.trim().replace(/\s/g, '').length < 18
      );
    });

    return () => {
      ValidatorForm.removeValidationRule('phone');
    };
  }, [businessPhoneNumber, phoneRegExp]);

  useEffect(() => {
    ValidatorForm.addValidationRule('noNegative', (value) => {
      return value >= 0;
    });

    return () => {
      ValidatorForm.removeValidationRule('noNegative');
    };
  }, [amountOfEmployees]);

  useEffect(() => {
    ValidatorForm.addValidationRule('valueLength255', (value) => {
      return value.trim().length < 256;
    });

    return () => {
      ValidatorForm.removeValidationRule('valueLength255');
    };
  }, [businessABNorACN]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      id: companyId,
      name: businessName,
      phone_number: businessPhoneNumber.trim().replace(/\s/g, ''),
      business_abn_acn: businessABNorACN.trim().replace(/\s/g, ''),
      employees_count: Number(amountOfEmployees),
      postal_address: postalAddress,
      postal_code: postalCode,
      postal_city: postalCity,
      postal_country: postalCountry,
      physical_address: physicalAddress,
      physical_code: physicalCode,
      physical_city: physicalCity,
      physical_country: physicalCountry,
    };

    NWClient.put(token, 'company', companyId, data, true)
      .then(() => {
        setPendingStatus(false);
        toast.success(t('messages.companyUpdated'), { theme: 'colored' });
      })
      .catch((error) => {
        setPendingStatus(false);
        setFieldsErrors(error.response.data);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  useEffect(() => {
    setLoading(true);
    NWClient.getCurrentCompany(token)
      .then((res) => {
        if (Array.isArray(res) ? res.length > 0 : Boolean(res)) {
          setCompanyId(res[0].id);
          setBusinessName(res[0].name);
          setBusinessPhoneNumber(res[0].phone_number);
          setBusinessABNorACN(res[0].business_abn_acn);
          setAmountOfEmployees(String(res[0].members.length));
          setPhysicalAddress(res[0].physical_address);
          setPostalAddress(res[0].postal_address);
          setPostalCity(res[0].postal_city);
          setPostalCode(res[0].postal_code);
          setPostalCountry(res[0].postal_country);
          setPhysicalCity(res[0].physical_city);
          setPhysicalCode(res[0].physical_code);
          setPhysicalCountry(res[0].physical_country);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  return (
    <>
      <TabPanel value={currentTab} index={1} prefix={'vertical'}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid>
            <Grid container item className='setting-panel-header' alignItems='center'>
              <CompanySettingsIcon />
              <h3>
                {t('settings.titles.business')}
                {isEmployee && (
                  <Tooltip title={t('messages.businessDetailsEmployee')} placement='top' arrow>
                    <InfoOutlinedIcon fontSize='small' />
                  </Tooltip>
                )}
              </h3>
            </Grid>
            <Divider />
            <div className='setting-panel-body'>
              <ValidatorForm onSubmit={handleSubmit}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.businessOrCompany') + ' *'}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setBusinessName(e.target.value)
                      }
                      name='businessName'
                      value={businessName}
                      validators={['required', 'valueLength255']}
                      errorMessages={[t('messages.fieldRequired'), t('messages.maxLength255')]}
                      disabled={isEmployee}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.businessPhoneNumber')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setBusinessPhoneNumber(e.target.value)
                      }
                      name='businessPhoneNumber'
                      value={businessPhoneNumber}
                      validators={['phone']}
                      errorMessages={[t('messages.enterCorrectPhoneNumber')]}
                      disabled={isEmployee}
                    />
                    {Boolean(fieldsErrors?.phone_number) && (
                      <FormHelperText error={true}>
                        {fieldsErrors?.phone_number.join('.')}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.ABNorACN')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setBusinessABNorACN(e.target.value)
                      }
                      name='businessABNorACN'
                      validators={['ABNorACNLength']}
                      errorMessages={[t('messages.ABNandACNMessage')]}
                      value={businessABNorACN}
                      disabled={isEmployee}
                    />
                    {Boolean(fieldsErrors?.business_abn_acn) && (
                      <FormHelperText error={true}>
                        {fieldsErrors?.business_abn_acn.join('.')}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.numberOfWorkers')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setAmountOfEmployees(e.target.value.replace(/\D/, ''));
                      }}
                      name='amountOfEmployees'
                      validators={['noNegative']}
                      errorMessages={[t('messages.valueGreaterEqualTo0')]}
                      value={amountOfEmployees}
                      disabled={true}
                    />
                    {Boolean(fieldsErrors?.employees_count) && (
                      <FormHelperText error={true}>
                        {fieldsErrors?.employees_count.join('.')}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    className='setting-panel-header ps-0 ml-2'
                    alignItems='center'
                  >
                    <MailIcon />
                    <h3>{t('labels.postalAddress')}</h3>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.postalAddress')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPostalAddress(e.target.value)
                      }
                      name='postalAddress'
                      value={postalAddress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.postalCode')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
                      name='postalCode'
                      value={postalCode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.cityCounty')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setPostalCity(e.target.value)}
                      name='postalCity'
                      value={postalCity}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      select
                      label={t('labels.country')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPostalCountry(e.target.value)
                      }
                      name='postalCountry'
                      value={postalCountry}
                    >
                      {countries.map((option) => (
                        <MenuItem key={option.isoCode} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Grid>
                  <Grid
                    container
                    item
                    className='setting-panel-header ps-0 ml-2'
                    alignItems='center'
                  >
                    <LocationIcon />
                    <h3>{t('labels.physicalAddress')}</h3>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.physicalAddress')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPhysicalAddress(e.target.value)
                      }
                      name='physicalAddress'
                      value={physicalAddress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.postalCode')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPhysicalCode(e.target.value)
                      }
                      name='physicalCode'
                      value={physicalCode}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.cityCounty')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPhysicalCity(e.target.value)
                      }
                      name='physicalCity'
                      value={physicalCity}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      select
                      label={t('labels.country')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPhysicalCountry(e.target.value)
                      }
                      name='physicalCountry'
                      value={physicalCountry}
                    >
                      {countries.map((option) => (
                        <MenuItem key={option.isoCode} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Grid>
                </Grid>
                {!isEmployee && (
                  <Grid container className='buttons-row' justifyContent='flex-end'>
                    <Button
                      type='submit'
                      role='button'
                      disabled={!canSubmit}
                      variant='contained'
                      size='medium'
                    >
                      {t('buttons.update')}
                    </Button>
                  </Grid>
                )}
              </ValidatorForm>
            </div>
          </Grid>
        )}
      </TabPanel>
      {pendingStatus ? <ProgressOverlay /> : null}
    </>
  );
};
